<template>
	<div class="my-explore">
		<div class="result-box">
			<el-popconfirm title="确认清除全部探索吗？" @onConfirm="removeAll()" class="remove" v-show="moiveList.length">
				<span
					slot="reference"
					style="
            width: 140px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
					>清除全部探索</span
				>
			</el-popconfirm>

			<div v-for="(item, index) in moiveList" :key="index" class="result-item">
				<div class="result-item-img">
					<!-- <img :src="getImg(item.videoInfo.poster, '166', '240')" alt="" /> -->
					<img :src="item.videoInfo.poster" style="width: 166px; height: 240px" alt="" :isWrapper="'a'" />
<!--					<lazy-pic :array="[item.videoInfo.poster, '166', '240']" :type="'poster'" :isWrapper="'a'" />-->
					<div class="moive-tag">
						{{ typeObject[item.videoInfo.videoType] }}
					</div>
				</div>
				<div class="result-item-text">
					<div class="result-item-text-box">
						<div class="result-item-text-box-title">
							{{ item.videoInfo.name }}
						</div>
						<div class="date">{{ item.releaseTime }}</div>
					</div>
					<div
						class="moive-button"
						style="margin-left: 20px"
						@click="goDetail(item.videoInfo.videoId)"
						v-show="item.status === 'onshow'"
					>
						<img :src="wantIcon" alt="" />
						<span>立即播放</span>
					</div>
					<div v-show="item.status === 'matching'">
						<div
							style="
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 28px;
                color: #ff3465;
                margin-left: 20px;
                margin-top: 40px;
              "
						>
							还差{{ item.remain }}人
						</div>
					</div>
					<div v-show="item.status === 'deleted'">
						<div
							style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 28px;
                color: rgba(121, 122, 142, 1);
                margin-left: 20px;
                margin-top: 40px;
              "
						>
							<img :src="warningIcon" style="width: 20px; height: 20px; margin-right: 10px" alt="" />
							<span>不符合条件</span>
						</div>
					</div>
					<div v-show="item.status === 'ongoing'">
						<div
							style="
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 28px;
                color: #ff3465;
                margin-left: 20px;
                margin-top: 40px;
              "
						>
							即将上映
						</div>
					</div>

					<div>
						<div
							style="
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 28px;
                color: #ff3465;
                margin-left: 20px;
                margin-top: 40px;
              "
						>
							<el-popconfirm title="确定移除此探索记录吗？" @onConfirm="removeById(item)">
								<el-button type="text" style="color: rgba(255, 52, 101, 1)" slot="reference"
								>移除</el-button
								>
							</el-popconfirm>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="no-work" v-show="!moiveList.length">
			<img :src="emptyIcon" alt="" />
			<span>暂无内容</span>
		</div>
		<div class="bodymovinanim" v-show="loadingShow">
			<div>
				<img :src="loadingIcon" alt="" />
			</div>
		</div>
		<!-- <page-select v-show="moiveList.length" :pagination="pagination" @pageChange="pageChange"></page-select> -->
	</div>
</template>

<script>
import pageSelect from "./page-select"

let isLoad = false
export default {
	name: "my-explore",
	data() {
		return {
			moiveList: [],
			wantIcon: require("@assets/explore/want.png"),
			emptyIcon: require("@assets/explore/empty.png"),
			warningIcon: require("@assets/center/warning.png"),
			pageSize: 20,
			currentPage: 1,
			pagination: {
				currentPage: 1,
				pageSize: 20,
				numFound: 0,
				totalPage: 0,
			},
			thumb: require("@assets/thumb.png"),
			typeObject: {
				movie: "电影",
				tv: "剧集",
      },
      loadingIcon: require("@assets/loading.gif"),
      loadingShow: false
		}
	},
	methods: {
		async removeAll() {
			console.log(this.moiveList.map((i) => i.exploreId));
			const result = await this.api.deleteExploreAll({
				exploreIds: this.moiveList.map((i) => i.exploreId),
			})
			if (result.code === 1) {
				this.$message.success("清除成功")
				this.moiveList = []
				this.init()
			} else {
				this.$message.error(result.msg)
			}
		},
		async removeById(item) {
			console.log('item', item)
			const result = await this.api.deleteExploreAll({
				exploreIds: [item.exploreId],
			})
			if (result.code === 1) {
				this.$message.success("清除成功")
				this.moiveList = this.moiveList.filter(a => a.exploreId !== item.exploreId)
				this.init()
			} else {
				this.$message.error(result.msg)
			}
		},
		pageChange(e) {
			this.pagination.currentPage = e
			this.init()
			console.log(e)
		},
		async init() {

			isLoad = true
			const { pageSize, currentPage } = this.pagination
			const result = await this.api.getMyExplore({
				pageSize,
				currentPage,
			})
			this.moiveList = [ ...result.data.list]


			this.pagination = result.data.pagination
			console.log(result)
			setTimeout(() => {
				isLoad = false
			}, 500)
		},
		getScroll() {
			let _this = this
			document.querySelector(".el-main").addEventListener(
				"scroll",
				function(e) {
					// console.log(e)
					if (_this.pagination.totalPage !== _this.pagination.currentPage) {
						_this.loadingShow = true
					}
					const { scrollHeight, scrollTop, offsetHeight } = e.target
					console.log(parseInt(scrollTop + offsetHeight), scrollHeight)
					console.log("相减后的绝对值", Math.abs(scrollHeight - parseInt(scrollTop + offsetHeight)))
					if (Math.abs(scrollHeight - parseInt(scrollTop + offsetHeight)) < 5) {
						console.log("到底了")

						setTimeout(() => {
							if (!isLoad) {
								if (_this.pagination.totalPage === _this.pagination.currentPage) return
								_this.pagination.currentPage += 1

								_this.pageChange(_this.pagination.currentPage)
							}
						}, 500)
					}
				},
				true
			)
		},
	},
	mounted() {
		this.init()
		this.getScroll()
	},
	activated() {
		this.init()
	},
	components: {
		pageSelect,
	},
}
</script>

<style lang="scss" scoped>
.no-work {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 200px;
	img {
		width: 300px;
		height: 210px;
	}
	span {
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 21px;
		color: #797a8e;
		margin-top: 20px;
		user-select: none;
	}
}
.result-box {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 40px;
	position: relative;
	.remove {
		width: 140px;
		height: 50px;
		background: rgba(255, 52, 101, 0.1);
		border-radius: 10px;
		position: absolute;
		right: 0;
		top: 0;
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 21px;
		color: #ff3465;
		display: flex;
		justify-content: center;
		align-items: center;
		user-select: none;
		cursor: pointer;
	}
	.result-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-right: 100px;
		margin-bottom: 40px;
		.result-item-img {
			position: relative;
			width: 166px;
			height: 240px;
			border-radius: 10px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
			}
			.moive-tag {
				position: absolute;
				right: 5px;
				top: 5px;
				z-index: 11;
				width: 50px;
				height: 30px;
				background: #121330;
				opacity: 0.9;
				border-radius: 5px;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 20px;
				color: #ff3465;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 4px;
			}
		}
		.result-item-text {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
			height: 240px;
			.result-item-text-box {
				margin-top: 40px;
				margin-left: 20px;
				.result-item-text-box-title {
					font-size: 19px;
					font-family: PingFang SC;
					font-weight: 500;
					line-height: 26px;
					color: #b6b7c3;
					width: 166px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.result-item-text-box-title:hover {
					cursor: pointer;
					text-decoration: underline;
				}
				.date {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #797a8e;
					margin-top: 6px;
				}
				.date:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
			.moive-button {
				width: 166px;
				height: 50px;
				background: rgb(26, 29, 54);
				border-radius: 5px;
				margin-top: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				margin-top: 60px;
				img {
					width: 30px;
					height: 30px;
					margin-right: 10px;
				}
				span {
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 500;
					line-height: 22px;
					color: #797a8e;
				}
			}
		}
	}
}
</style>
