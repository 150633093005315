<template>
	<div class="page-select">
		<el-pagination
			:current-page="pagination.currentPage"
			:page-count="pagination.totalPage"
			:page-size="pagination.pageSize"
			background
			layout="prev, pager, next"
			@current-change="handleChange"
		>
		</el-pagination>
	</div>
</template>

<script>
export default {
	name: "page-select",
	data() {
		return {}
	},
	methods: {
		handleChange(e) {
			this.$emit("pageChange", e)
		},
	},
	mounted() {},
	props: {
		pagination: {
			type: Object,
			default: () => {
				return {
					currentPage: 0,
					pageSize: 0,
					numFound: 0,
					totalPage: 0,
				}
			},
		},
	},
}
</script>

<style lang="scss">
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
	background-color: #12142e !important;
	color: white !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
	background: #ff3465 !important;
}
.page-select {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 24px;
}
</style>
